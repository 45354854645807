body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url('../public/arad.jpg');
}

.container {
    margin-left: 10rem;
    display: flex;
    transform: translateY(10%);
    align-items: left;
    height: 100vh;
    flex-direction: column;
    color: white;
    font-size: 1.5rem;
}


/* Container when screen width is small */

@media (max-width: 768px) {
    .container {
        margin-left: 2rem;
    }
}